import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';

import { ChatContext, ChatMessageElement } from 'api/chatApi/chatApi.types';
import {
  BotType,
  BotTypeMessage,
  DefaultBotTypeMessage,
  DefaultBotTypes,
  SummaryBotTypeMessage,
  TagOnboardingBotTypeMessage,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { RetrievalUnitEnum } from 'common/enums';

import { Conversation, ConversationPayload } from '../Chat.types';

const addMessageToConversation = <T extends object = {}>(
  message: DefaultBotTypeMessage | TagOnboardingBotTypeMessage,
  conversation: Conversation<T> | null,
  botType?: BotType
): Conversation<T> => ({
  botType: conversation?.botType || botType || 'default',
  messages: message.message
    ? [
        ...(conversation?.messages || []),
        {
          content: message.message,
          sender: ChatMessageSenderEnum.User,
          ...(message.image && message.image.includes('data:image/png;base64')
            ? { image: message.image }
            : {}),
        },
      ]
    : [],
  payload: conversation?.payload,
});

const createNewSummaryConversation = <T extends object = {}>(
  message: SummaryBotTypeMessage,
  payload?: T
): Conversation<T> => ({
  botType: 'summary',
  messages: [
    {
      content: message.message,
      sender: ChatMessageSenderEnum.User,
    },
  ],
  payload,
});

const createEmptyConversation = <T extends object = {}>(
  botType: BotType,
  payload?: T
): Conversation<T> => ({
  botType,
  messages: [],
  payload,
});

export const buildChatConversation = <T extends object = {}>(
  message: BotTypeMessage,
  conversation: Conversation<T> | null
): Conversation<T> => {
  switch (message.botType) {
    case 'summary':
      return createNewSummaryConversation(message, conversation?.payload);
    case 'quizbot':
      return createEmptyConversation(message.botType, conversation?.payload);
    default:
    case 'chat_with_tag':
    case 'chat_with_pdf':
    case 'chat_with_qa_widget':
      return addMessageToConversation(message, conversation, message.botType);
  }
};

export const getContext = (
  docsIds: string[] | undefined
): ChatContext | undefined => {
  return docsIds && docsIds.length
    ? {
        fromDocIds: {
          contextFields: [],
          docId: docsIds,
          retrievalUnit: RetrievalUnitEnum.Document,
        },
      }
    : undefined;
};

export const getPredefinedConversation = (
  docsIds: string[],
  previousMessages: ChatMessageElement[],
  botType: DefaultBotTypes,
  retrievalUnit: RetrievalUnitEnum
): Conversation<ConversationPayload> | null => {
  return {
    botType: botType,
    messages: previousMessages,
    payload: {
      docIds: docsIds,
      originalUrl: window.location.href,
      retrievalUnit: retrievalUnit,
    },
  };
};
