import type {
  ChatMessage as ChatMessageStream,
  ChatMessageEvidence,
  ChatMessageEvidence as ChatMessageEvidenceStream,
  ChatResponseForm as ChatResponseFormStream,
  ContentPart,
} from '@zarn/vendor/dist/chat-service';

import { RetrievalUnitEnum } from 'common/enums';
import { serializeKey } from 'common/utils/serialize.helpers';

import type {
  ChatMessageElement,
  ChatPayload,
  Evidence,
  MessageContext,
} from './chatApi.types';
import {
  buildEvidenceLabel,
  deserializeFunctionCallProps,
  deserializeRetrievalUnit,
  deserializeSender,
  serializeBotParams,
  serializeChatContext,
} from './chatApi.utils';

// Serialize

const serializeEvidenceItem = (
  evidenceItem: Evidence
): ChatMessageEvidenceStream => ({
  document_hit_url: evidenceItem.documentHitUrl!,
  text_extract: evidenceItem.textExtract,
  ...serializeKey(evidenceItem, 'anchorText', 'anchor_text'),
  ...serializeKey(evidenceItem, 'explanation'),
  ...serializeKey(evidenceItem, 'uri'),
});

const serializeContentParts = ({
  content,
  contextDocsIds,
  retrievalUnit,
}: {
  content: string;
  contextDocsIds?: string[];
  retrievalUnit?: RetrievalUnitEnum;
}): { content_parts: ContentPart[] } => {
  const contentElement: ContentPart = {
    text: content,
    type: 'text',
  };

  const contextElement: ContentPart | undefined = contextDocsIds && {
    context: {
      document_context: {
        document_ids: contextDocsIds,
        retrieval_unit: retrievalUnit as string,
      },
    },
    type: 'context',
  };

  const contentParts = {
    content_parts: contextElement
      ? [contextElement, contentElement]
      : [contentElement],
  };

  return contentParts;
};

export const serializeChatResponseForm = (
  conversationItem: ChatMessageElement
): ChatMessageStream => {
  const chatMessageElement: ChatMessageStream = {
    content: conversationItem.content,
    ...serializeContentParts({
      content: conversationItem.content,
      contextDocsIds: conversationItem.context?.docsIds,
      retrievalUnit: conversationItem.context?.retrievalUnit,
    }),
    sender: conversationItem.sender,
    ...(conversationItem.image ? { image_uri: conversationItem.image } : {}),
  };

  if (conversationItem.evidences) {
    chatMessageElement.evidences = conversationItem.evidences.map(
      serializeEvidenceItem
    );
  }

  if (conversationItem.functionCallRequest) {
    chatMessageElement.function_call_request =
      conversationItem.functionCallRequest;
  }

  return chatMessageElement;
};

export const serializeChatStreamPayload = ({
  chatResponseForm,
}: ChatPayload): ChatResponseFormStream => ({
  // @ts-expect-error
  bot_type: chatResponseForm.botType,
  context: serializeChatContext(chatResponseForm.context),
  conversation: chatResponseForm.conversation.map(serializeChatResponseForm),
  ...(chatResponseForm.botParams
    ? { bot_params: serializeBotParams(chatResponseForm.botParams) }
    : {}),
});

// Deserialize

export const getEvidenceDocumentId = (evidence: ChatMessageEvidence) => {
  if ('document_id' in evidence && evidence.document_id) {
    return `${evidence.document_id}`;
  }

  if (evidence.document_hit_url) {
    const url = new URLSearchParams(evidence.document_hit_url);
    if (url.get('property_values')) {
      return url.get('property_values');
    }
  }

  return null;
};

export const deserializeEvidenceItem = (
  evidenceItem: ChatMessageEvidence,
  index: number
): Evidence => ({
  documentId: getEvidenceDocumentId(evidenceItem),
  textExtract: evidenceItem.text_extract!,
  ...serializeKey(evidenceItem, 'anchor_text', 'anchorText'),
  ...serializeKey(evidenceItem, 'document_hit_url', 'documentHitUrl'),
  label: buildEvidenceLabel(index),
});

export const deserializeContentParts = (
  contentParts: ContentPart[] | undefined,
  messageContent: string
): {
  content: string;
  context?: MessageContext;
} => {
  if (!contentParts) {
    return { content: messageContent };
  }

  const { content, contextDocsIds, retrievalUnit } = contentParts.reduce(
    (acc, part) => {
      if (
        part.type === 'context' &&
        part.context?.document_context?.document_ids
      ) {
        return {
          ...acc,
          contextDocsIds: [
            ...acc.contextDocsIds,
            ...part.context.document_context.document_ids,
          ],
          retrievalUnit: part.context.document_context.retrieval_unit,
        };
      } else if (part.type === 'text' && part.text) {
        return {
          ...acc,
          content: acc.content + part.text + ' ',
        };
      }
      return acc;
    },
    {
      content: '',
      contextDocsIds: [] as string[],
      retrievalUnit: '',
    }
  );

  return {
    content: content.trim(),
    ...(contextDocsIds.length > 0
      ? {
          context: {
            docsIds: contextDocsIds,
            retrievalUnit: deserializeRetrievalUnit(retrievalUnit),
          },
        }
      : {}),
  };
};

export const deserializeChatStreamMessage = (
  message: ChatMessageStream
): ChatMessageElement => {
  const sender = deserializeSender(message.sender);
  const evidences = message.evidences
    ? message.evidences.map(deserializeEvidenceItem)
    : undefined;

  const result: ChatMessageElement = {
    ...deserializeContentParts(message.content_parts, message.content),
    sender,
    ...(message.function_call_request
      ? {
          functionCallRequest: deserializeFunctionCallProps(
            message.function_call_request
          ),
        }
      : {}),
    ...(message.image_uri ? { image: message.image_uri } : {}),
  };

  if (evidences) {
    result.evidences = evidences;
  }

  return result;
};
