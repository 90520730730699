import { captureException } from '@sentry/react';

import { ChatMessageElement, Evidence } from 'api/chatApi/chatApi.types';
import { deserializeContent } from 'api/chatApi/chatApi.utils';
import { defined, Nullable } from 'common/utils/assert';
import { decrypt, encrypt } from 'common/utils/crypto';
import { buildMarkdownLexer, getLexerLinks } from 'common/utils/markdown.utils';
import { Conversation } from 'containers/Chat/Chat.types';

import { ParserInterface } from './Parser.interface';

export class Parser<T extends object = {}> implements ParserInterface<T> {
  static CHAT_TITLE = '# Chat';
  private static PREFIX = '    >';

  constructor(public content: Nullable<string>) {}

  static isConversation(content: Nullable<string>) {
    return !!content && content.startsWith(Parser.CHAT_TITLE);
  }

  static buildMessageMarkdown = (message: ChatMessageElement): string =>
    `${Parser.buildSenderMarkdown(
      message.sender
    )}\n\n${Parser.buildContentMarkdown(
      Parser.deserializeContentWithEvidencesLabels(message)
    )}${Parser.buildEvidencesSuffix(message)}${Parser.buildImageMarkdown(
      message.image
    )}\n\n`;

  static buildConversationComment = async <TL extends object = {}>(
    conversation: Conversation<TL>
  ) => `[//]: # (${encrypt(JSON.stringify(conversation))})`;

  public static prepareMessage = (
    message: Pick<ChatMessageElement, 'content' | 'evidences'>
  ) => {
    return Parser.deserializeMessageContent(message).replaceAll(
      /\[\^[0-9]*]/g,
      (match) => `[${match.slice(2, -1)}]`
    );
  };

  public static prepareLabel = (label: string) => {
    return label.replaceAll(
      /\[\^[0-9]*]/g,
      (match) => `[${match.slice(2, -1)}]`
    );
  };

  private static deserializeMessageContent = (
    message: Pick<ChatMessageElement, 'content' | 'evidences'>
  ): string =>
    message.evidences
      ? deserializeContent(message.content, message.evidences)
      : message.content;

  private static buildSenderMarkdown = (sender: string): string =>
    `* **${sender}:**`;

  private static buildContentMarkdown = (content: string): string =>
    content
      .replaceAll('\n', '\n\n')
      .split('\n')
      .map((line) => (line ? `${Parser.PREFIX} ${line}` : Parser.PREFIX))
      // .slice(0, -1)
      .join('\n')
      .replaceAll(`${Parser.PREFIX}\n${Parser.PREFIX}\n`, `${Parser.PREFIX}\n`)
      .replaceAll(`${Parser.PREFIX}\n${Parser.PREFIX}\n`, `${Parser.PREFIX}\n`)
      .replaceAll(`${Parser.PREFIX}\n${Parser.PREFIX} |`, `${Parser.PREFIX} |`);

  private static buildImageMarkdown = (image?: string | null): string =>
    image ? `\n\n![Image](${image})` : '';

  private static deserializeContentWithEvidencesLabels = (
    message: ChatMessageElement
  ): string =>
    message.evidences
      ? deserializeContent(message.content, message.evidences)
      : message.content;

  private static processEvidenceText = (text: string) =>
    `${text}`.split('\n').join('. ');

  private static buildEvidences = (evidences: Evidence[]) =>
    evidences.reduce(
      (acc, evidence) =>
        `${acc}\n${Parser.PREFIX}\n${Parser.PREFIX} ${
          evidence.label
        }: ${Parser.processEvidenceText(evidence.textExtract)}`,
      ''
    );

  private static buildEvidencesSuffix = (message: ChatMessageElement): string =>
    message.evidences ? Parser.buildEvidences(message.evidences) : '';

  private static updateBotTypeInJson = <TL extends object = {}>(
    json: string
  ): Conversation<TL> => {
    //This is a hack to support notes that were saved with Bot Type that is not supported anymore.
    //We should not expand this list.
    //If we migrate all the notes in the data base to the correct Bot Types we can remove this hack.
    const parsedJson = JSON.parse(json);

    if (parsedJson.botType === 'multi_doc_chat_with_evidence') {
      parsedJson.botType = 'chat_with_tag';
    }
    if (parsedJson.botType === 'default_evidence') {
      parsedJson.botType = 'chat_with_pdf';
    }
    return parsedJson as Conversation<TL>;
  };

  public async disassemble(): Promise<Conversation<T> | null> {
    try {
      if (!this.content) {
        return null;
      }
      const lexersList = buildMarkdownLexer(this.content);
      const links = getLexerLinks(lexersList);
      const comment = links?.['//']?.title;
      const json = decrypt(defined(comment));
      return Parser.updateBotTypeInJson(json);
    } catch (error) {
      captureException(error);
      return null;
    }
  }

  public async assemble(conversation: Conversation<T>): Promise<string> {
    const markdown = conversation.messages.reduce(
      (acc, message) => `${acc}${Parser.buildMessageMarkdown(message)}`,
      ''
    );

    const comment = await Parser.buildConversationComment(conversation);

    return `${Parser.CHAT_TITLE}\n\n${markdown}${comment}`;
  }
}
