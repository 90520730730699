import { useCallback, useContext } from 'react';

import { HomePageContext } from 'pages/HomePage/HomePageContextProvider';

import { useNavigationWithState } from 'common/hooks/useNavigationWithState';
import { useLoggedInFeature } from 'containers/Auth/hooks/useLoggedInFeature';

import { useRetrievalUnitFromNote } from './useRetrievalUnitFromNote';

interface Props {
  noteContent: string;
  noteId: number;
  onOpenChatClick?: () => void;
}

export const useOpenQAChat = ({
  noteContent,
  noteId,
  onOpenChatClick,
}: Props) => {
  const navigation = useNavigationWithState();

  const openChatNote = useLoggedInFeature<boolean>(() => {
    navigation.push(`/?tab=chat&noteId=${noteId}`);
  });

  const homeContext = useContext(HomePageContext);
  const { getRetrievalUnitFromNote } = useRetrievalUnitFromNote(noteContent);

  const openQAChat = useCallback(async () => {
    onOpenChatClick?.();

    const retrievalUnit = await getRetrievalUnitFromNote();
    if (retrievalUnit) {
      homeContext?.setRetrievalUnit(retrievalUnit);
    }

    openChatNote(true);
  }, [onOpenChatClick, openChatNote, getRetrievalUnitFromNote, homeContext]);

  return { openQAChat };
};
