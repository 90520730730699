import { useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';

import { SERVICE_URL } from 'api/apiConfig';
import axiosInstance from 'api/axiosInstance';
import { isExist } from 'common/utils/assert';
import { deserializeSearchHit } from 'common/utils/documents';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

interface UseAllHitsFromUrlProps {
  docsUrl: string[];
}

export const useAllHitsFromUrl = ({ docsUrl }: UseAllHitsFromUrlProps) => {
  const [docsData, setDocsData] = useState<RetrievalUnitData[]>([]);

  const getDocumentData = async (
    documentHitUrl: string
  ): Promise<AxiosResponse<RetrievalUnitData | null>> => {
    // TODO: Chang it to use API from vendors
    const response = await axiosInstance.get(`${SERVICE_URL}${documentHitUrl}`);
    const { data, ...rest } = response;
    const hit = data.hits[0];

    return {
      ...rest,
      data: hit ? deserializeSearchHit(hit) : null,
    };
  };

  useEffect(() => {
    const fetchHits = async () => {
      const hits = await Promise.all(
        docsUrl.map((docUrl) => getDocumentData(docUrl))
      );

      const data = hits
        .filter((hit) => isExist(hit) && isExist(hit.data))
        .map((hit) => hit!.data)
        .filter(isExist);
      setDocsData(data);
    };

    void fetchHits();
  }, []);

  return { docsData };
};
