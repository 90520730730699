import React, { useCallback, useEffect, useRef } from 'react';

import AddCommentIcon from '@mui/icons-material/AddComment';
import { Box, Theme, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';

import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import { LoaderContainer } from 'common/components/Loaders/LoaderContainer/LoaderContainer';
import { BoxWithoutScroll } from 'common/components/WithoutScroll/WithoutScroll';
import { throttle } from 'common/utils/throttle';
import { selectUser } from 'containers/User/user.slice';

import { ChatProps } from './Chat.props';
import { ConversationList } from './Conversation/ConversationList';
import { Greetings } from './Greetings/Greetings';
import { useChat } from './hooks/useChat';
import { useChatMessages } from './hooks/useChatMessages';
import { LoadingMessage } from './LoadingMessage/LoadingMessage';
import { Message } from './Message';
import { MessageInput } from './MessageInput';
import { ModeButtons } from './ModeButtons';
import { SavingStatus } from './SavingStatus/SavingStatus';

export const Chat = <T extends object = {}>({
  disabled,
  isEvidenceDialog,
  isLoading,
  isSaving,
  onEvidenceChunkClick,
  onEvidenceItemClick,
  onSave,
  options,
  predefinedConversation,
  renderContextAction,
}: ChatProps<T>) => {
  const { t } = useTranslation('common');
  const { palette } = useTheme<Theme>();
  const chatMessagesRef = useRef<HTMLDivElement | null>(null);
  const { buildDefaultMessage } = useChatMessages();
  const user = useSelector(selectUser);

  const {
    botParams,
    context,
    defaultBotType,
    greetings,
    header,
    placeholder,
    supportedBotTypes,
  } = options;

  const {
    conversation,
    error,
    isLoadingMessage,
    isLoadingNote,
    loadingMessage,
    onReset,
    onSendMessage,
  } = useChat({
    botParams,
    context,
    onSave,
    predefinedConversation,
  });
  const isError = !!error;

  const scrollTo = useCallback((top: number) => {
    chatMessagesRef.current?.scroll({
      behavior: 'smooth',
      top,
    });
  }, []);

  const scrollToBottom = useCallback(
    (scrollHeight: number) => {
      scrollTo(scrollHeight);
    },
    [scrollTo]
  );

  useEffect(() => {
    if (chatMessagesRef.current) {
      scrollToBottom(chatMessagesRef.current.scrollHeight);
    }
  }, [conversation, isLoadingNote, scrollToBottom]);

  const scroll = useCallback(
    throttle(() => {
      if (chatMessagesRef.current) {
        scrollToBottom(chatMessagesRef.current.scrollHeight);
      }
    }, 100),
    [scrollToBottom]
  );

  useEffect(() => {
    scroll();
  }, [loadingMessage, scroll]);

  const handleSubmit = useCallback(
    (message: string) =>
      onSendMessage(buildDefaultMessage(message, defaultBotType)),
    [buildDefaultMessage, onSendMessage, defaultBotType]
  );

  const history = useHistory();

  const isCurrentPageDiscover = history.location.pathname === '/';

  const isNewChatDisabled =
    isLoadingMessage ||
    isLoadingNote ||
    !conversation ||
    !conversation.messages.length ||
    isCurrentPageDiscover;

  const isChatDisabled =
    disabled || isLoadingMessage || isLoadingNote || isSaving || isLoading;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      {header}
      <LoaderContainer loading={isLoadingNote}>
        <BoxWithoutScroll
          ref={chatMessagesRef}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            mt: 2,
            overflow: 'hidden scroll',
          }}
        >
          {!conversation?.messages.length &&
            !isLoadingMessage &&
            !loadingMessage && (
              <Greetings greetings={greetings} isLoading={isLoading} />
            )}

          {!conversation && (
            <ModeButtons
              disabled={disabled}
              supportedBotTypes={supportedBotTypes}
              onClick={onSendMessage}
            />
          )}

          {conversation && !!conversation.messages.length && (
            <>
              {renderContextAction?.(conversation)}

              <ConversationList
                conversation={conversation}
                initials={user?.initials}
                isEvidenceDialog={isEvidenceDialog}
                onEvidenceChunkClick={onEvidenceChunkClick}
                onEvidenceItemClick={onEvidenceItemClick}
              >
                <SavingStatus isSaving={isSaving} />
              </ConversationList>
            </>
          )}

          {isError && (
            <Message
              isContent={true}
              sender={ChatMessageSenderEnum.Bot}
              sx={{ backgroundColor: palette.error.light }}
            >
              <Tooltip title={error?.message}>
                {t('chat.message.error')}
              </Tooltip>
            </Message>
          )}

          {(isLoadingMessage || loadingMessage) && (
            <LoadingMessage message={loadingMessage} />
          )}
        </BoxWithoutScroll>
      </LoaderContainer>

      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          gap: '0.25rem',
          padding: '0rem 0.5rem 1rem 0.5rem',
        }}
      >
        <Tooltip title={t('chat.moreMenu.start')}>
          <Box>
            <TrackedIconButton
              data-testid="new-chat-button"
              disabled={isNewChatDisabled}
              onClick={onReset}
            >
              <AddCommentIcon />
            </TrackedIconButton>
          </Box>
        </Tooltip>

        <MessageInput
          disabled={isChatDisabled}
          isLoading={isLoadingMessage || isLoading}
          placeholder={placeholder}
          onSubmit={handleSubmit}
        />
      </Box>
    </Box>
  );
};
