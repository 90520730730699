import React, { FC } from 'react';

import { Dialog, Typography } from '@mui/material';

import CollectionDialogContent from 'common/components/Dialogs/CollectionDialogContent/CollectionDialogContent';
import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import { endsLikeChunk, endsLikeDoc } from 'common/utils/docIdHelpers';
import DocsCollection from 'containers/Docs/DocsCollection';
import { useDocsDetails } from 'containers/Docs/hooks/useDocsDetails';
import RetrievalUnit from 'containers/RetrievalUnit/RetrievalUnit';
import { DocCardComposition } from 'containers/User/User.enum';

import { ChunkCard } from './ChunkCard';

interface DocsContextDialogProps {
  closeDialog: () => void;
  dialogOpen: boolean;
  docsIds: string[];
}

export const DocsContextDialog: FC<DocsContextDialogProps> = ({
  closeDialog,
  dialogOpen,
  docsIds,
}) => {
  const { data: docsData } = useDocsDetails({
    docsIds,
  });

  return (
    <Dialog
      aria-label="Doc context"
      maxWidth="md"
      open={dialogOpen}
      fullWidth
      onClose={closeDialog}
    >
      <DialogActionTitle onClose={closeDialog}>
        <Typography variant="body1">Documents used in the chat</Typography>
      </DialogActionTitle>

      <CollectionDialogContent dividers>
        <DocsCollection organizeDocIds={docsIds}>
          {docsData
            ?.filter((doc) => endsLikeDoc(doc.document.id))
            .map((doc) => (
              <RetrievalUnit
                cardComposition={DocCardComposition.Compressed}
                data={doc}
                key={doc.document.id}
              />
            ))}
          {docsIds?.filter(endsLikeChunk).map((chunkId) => (
            <ChunkCard chunkId={chunkId} key={chunkId} />
          ))}
        </DocsCollection>
      </CollectionDialogContent>
    </Dialog>
  );
};
